import http from "../axios/http"

//坐席列表
export let getSeatList = (params) => {
    return http.get("seat/list", { params })
}
//坐席添加
export let getSeatAdd = (params) => {
    return http.get("seat/add", { params })
}
//坐席添加
export let postSeatAdd = (params) => {
    return http.post("seat/add", params)
}
//坐席编辑
export let getSeatEdit = (params) => {
    return http.get("seat/edit", { params })
}
//坐席编辑
export let postSeatEdit = (params) => {
    return http.post("seat/edit", params)
}
//坐席删除
export let postSeatDel = (params) => {
    return http.post("seat/del", params)
}
<template>
  <div class="SeatManage">
    <div class="SeatManage-top" style="display: flex">
      <a-select placeholder="所属部门" style="width: 150px" allowClear>
        <!-- <a-select-option value="1">客服部</a-select-option>
        <a-select-option value="2">电销部</a-select-option>
        <a-select-option value="3">管理层</a-select-option> -->
        <a-select-option
          v-for="item in dept_list"
          :key="item.dept_id"
          :value="item.dept_id"
          >{{ item.dname }}</a-select-option
        >
      </a-select>
      <div style="margin-left: 40px">
        <a-input-group compact>
          <a-select v-model:value="value" style="width: 120px">
            <a-select-option value="1">用户名</a-select-option>
            <a-select-option value="2">分机号码</a-select-option>
          </a-select>
          <a-input-search
            placeholder="请输入"
            enter-button="搜索"
            style="width: 240px"
          />
        </a-input-group>
      </div>

      <a-button type="primary" style="margin-left: 40px" @click="add">
        添加
      </a-button>
      <a-button type="primary" style="margin-left: 10px" @click="addMultiple">
        批量添加
      </a-button>
      <a-button type="primary" style="margin-left: 10px"> 批量修改 </a-button>
    </div>

    <a-modal
      v-model:visible="modalVisible"
      :title="user_id ? '编辑坐席' : '添加坐席'"
      centered
      @ok="handleOk"
      @cancel="formRest"
      :maskClosable="false"
      :width="630"
      class="seatmodel"
      cancelText="取消"
      okText="确定"
    >
      <a-form :model="formState">
        <div class="row">
          <a-form-item label="坐席账号" name="username">
            <a-input placeholder="请输入" v-model:value="formState.username" />
          </a-form-item>
          <a-form-item label="坐席登入密码" name="password">
            <a-input placeholder="请输入" v-model:value="formState.password" />
          </a-form-item>
        </div>
        <div class="row">
          <a-form-item label="所属部门" name="dept_id">
            <a-select
              style="width: 183px"
              placeholder="所属部门"
              v-model:value="formState.dept_id"
            >
              <a-select-option
                v-for="item in dept_list"
                :key="item.dept_id"
                :value="item.dept_id"
                >{{ item.dname }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="分机密码" name="ext_password">
            <!-- <a-select
              style="width: 183px"
              placeholder="分机密码"
              v-model:value="formState.ext_password"
            >
              <a-select-option
                v-for="item in ext_list"
                :key="item.ext_password"
                :value="item.ext_password"
                >{{ item.name }}</a-select-option
              >
            </a-select> -->
            <a-input
              placeholder="请输入"
              v-model:value="formState.ext_password"
            />
          </a-form-item>
        </div>
        <div class="row">
          <a-form-item label="机器人数量" name="robot">
            <a-input placeholder="请输入" v-model:value="formState.robot" />
          </a-form-item>
          <a-form-item label="用户状态" name="state">
            <div style="width: 174px">
              <a-switch
                checked-children="开启"
                un-checked-children="关闭"
                v-model:checked="formState.state"
              ></a-switch>
            </div>
          </a-form-item>
        </div>
        <div class="row">
          <a-form-item label="空号检测" name="da_status">
            <div style="width: 174px">
              <a-radio-group v-model:value="formState.da_status">
                <a-radio value="1">开启</a-radio>
                <a-radio value="2">关闭</a-radio>
              </a-radio-group>
            </div>
          </a-form-item>
          <a-form-item label="同步分机" name="tongbu" v-if="!user_id">
            <div style="width: 174px">
              <a-radio-group v-model:value="formState.tongbu">
                <a-radio value="1">是</a-radio>
                <a-radio value="2">否</a-radio>
              </a-radio-group>
            </div>
          </a-form-item>
        </div>
        <!-- <div class="row last">
          <a-form-item label="备注" name="remark">
            <a-textarea placeholder="请输入" v-model:value="formState.remark" />
          </a-form-item>
        </div> -->
        <div style="color: red; margin-left: 39px">
          提醒： 1. 分机密码中尽量不要有连续的字母或数字，例如 ABC xyz 1234等
          <span style="color: red; margin-left: 46px; display: block">
            2. 分机密码中尽量不要有键盘按键连续的内容，例如 qwer ASD !@#$等
          </span>
          <span style="color: red; margin-left: 46px">
            3. 分机密码格式示例：TAV#1532afhj@qF344
          </span>
          <span style="color: red; margin-left: 46px; display: block">
            4. 坐席账号以英文字母开头，数字结尾，例如 q123456
          </span>
        </div>
        <!-- <a-form-item label="提醒">
          <span style="color: red"
            >坐席账号与分机账号一致，坐席登陆密码可修改，分机密码不可修改</span
          >
        </a-form-item> -->
      </a-form>
    </a-modal>

    <!-- :pagination="{ defaultPageSize: 10 }" -->
    <a-table
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :pagination="{
        current: requestParams.page,
        total: total,
        pageSize: requestParams.limit,
        showSizeChanger: true,
        showTotal: (total) => `总共 ${total} 条`,
        pageSizeOptions: ['20', '50', '100'],
      }"
      @change="pageChange"
      :columns="columns"
      :data-source="data"
      rowKey="user_id"
      style="margin-top: 20px"
    >
      <template #state="{ record }">
        <a-switch
          checked-children="正常"
          un-checked-children="锁定"
          v-model:checked="record.state"
        />
      </template>
      <template #operation2="{ record }">
        <a-button
          type="primary"
          style="margin-right: 4px"
          size="small"
          @click="handleEdit(record.user_id)"
          >编辑</a-button
        >
        <a-popconfirm
          title="确定删除吗？"
          ok-text="Yes"
          cancel-text="No"
          @confirm="handleDel(record.user_id)"
        >
          <a-button size="small">删除</a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <a-modal
      v-model:visible="visible"
      title="批量添加坐席"
      centered
      @ok="handleOkMul"
      :maskClosable="false"
      cancelText="取消"
      okText="确定"
      :width="630"
      class="batch-model"
    >
      <a-form :model="formBatch">
        <a-form-item label="坐席数量" required>
          <a-input placeholder="请输入" />
        </a-form-item>
        <a-form-item label="新增方式" required>
          <a-radio-group name="radioGroup" v-model:value="formBatch.method">
            <a-radio value="1">系统配置</a-radio>
            <a-radio value="2">手动配置</a-radio>
          </a-radio-group>
        </a-form-item>
        <!-- <div > -->
        <a-form-item v-if="formBatch.method === '2'" label="分机号" required>
          <a-input placeholder="请输入" />
        </a-form-item>
        <a-form-item v-if="formBatch.method === '2'" label="起始工号" required>
          <a-input placeholder="请输入" />
        </a-form-item>
        <a-form-item v-if="formBatch.method === '2'" label="坐席密码">
          <a-input placeholder="请输入" />
        </a-form-item>
        <!-- </div> -->
        <a-form-item label="生成密码" required>
          <!-- <a-input placeholder="请输入" /> -->
          <a-select v-model:value="formBatch.generatePwd" style="width: 183px">
            <a-select-option value="1">相同</a-select-option>
            <a-select-option value="2">依次递增</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="认证密码" required>
          <a-input placeholder="请输入" />
        </a-form-item>
        <!-- <div class="row">
          <a-form-item label="坐席账号" required>
            <a-input placeholder="请输入" />
          </a-form-item>
          <a-form-item label="坐席密码" required>
            <a-input placeholder="请输入" />
          </a-form-item>
        </div>
        <div class="row">
          <a-form-item label="坐席数量" required>
            <a-input placeholder="请输入" />
          </a-form-item>
          <a-form-item label="机器人数量" required>
            <a-input placeholder="请输入" />
          </a-form-item>
        </div>
        <div class="row">
          <a-form-item label="部门">
            <a-select
              style="width: 183px"
              placeholder="请选择"
            >
              <a-select-option
                v-for="item in dept_list"
                :key="item.dept_id"
                :value="item.dept_id"
                >{{ item.dname }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </div> -->
      </a-form>
      <div style="color: red; margin-left: 39px">
        提醒： 1. 分机密码中尽量不要有连续的字母或数字，例如 ABC xyz 1234等
        <span style="color: red; margin-left: 46px; display: block">
          2. 分机密码中尽量不要有键盘按键连续的内容，例如 qwer ASD !@#$等
        </span>
        <span style="color: red; margin-left: 46px">
          3. 分机密码格式示例：TAV#1532afhj@qF344
        </span>
        <span style="color: red; margin-left: 46px; display: block">
          4. 坐席账号以英文字母开头，数字结尾，例如 q123456
        </span>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { reactive, ref, toRefs, onMounted } from "vue";
import { message } from "ant-design-vue";

const columns = [
  {
    title: "用户名",
    dataIndex: "username",
  },
  {
    title: "用户ID",
    dataIndex: "user_id",
  },
  // {
  //   title: "状态",
  //   dataIndex: "operation",
  //   slots: {
  //     customRender: "operation",
  //   },
  // },
  {
    title: "状态",
    dataIndex: "state",
    slots: {
      customRender: "state",
    },
  },
  {
    title: "机器人数量",
    dataIndex: "amount",
  },
  {
    title: "分机号码",
    dataIndex: "ext_number",
  },
  {
    title: "操作",
    // key: "operation",
    fixed: "right",
    width: "135px",
    dataIndex: "operation2",
    slots: {
      customRender: "operation2",
    },
  },
];
import {
  getSeatList,
  postSeatAdd,
  // getSeatAdd,
  getSeatEdit,
  postSeatEdit,
  postSeatDel,
} from "../../service/seatmanage";
export default {
  name: "SeatManage",

  setup() {
    const state = reactive({
      modalVisible: false,
      visible: false,
      selectedRowKeys: [],
      data: [],
      total: undefined,
      ext_list: [],
      dept_list: [],
      user_id: undefined,
    });
    const requestParams = reactive({
      page: 1,
      limit: 20,
    });
    onMounted(() => {
      init();
    });
    // const getSeatAddFun = async () => {
    //   const res = await getSeatAdd({
    //     access_token: sessionStorage.getItem("token"),
    //   });
    //   console.log(res);
    //   state.dept_list = res.data.data.dept_list;
    //   state.ext_list = res.data.data.ext_list;
    // };
    const init = async () => {
      const res = await getSeatList({
        access_token: sessionStorage.getItem("token"),
        ...requestParams,
      });
      state.data = res.data.data.dept.list;
      state.dept_list = res.data.data.dept_list;
      state.data.forEach(
        (item) => (item.state = item.state === "1" ? true : false)
      );
      state.total = res.data.data.dept.total;
      console.log(res);
    };
    const formState = reactive({
      ext_password: undefined,
      username: undefined,
      dept_id: undefined,
      password: undefined,
      robot: undefined,
      state: true,
      da_status: "1",
      tongbu: "1",
    });
    const formRest = () => {
      formState.ext_password = undefined;
      formState.username = undefined;
      formState.dept_id = undefined;
      formState.password = undefined;
      formState.robot = undefined;
      formState.state = true;
      formState.da_status = "1";
      state.user_id = undefined;
    };
    // let handelQuYangChange = (e) => {
    //   console.log(Number(e.key));
    //   state.data[Number(e.key)].quyang = !e.quyang;
    // };
    let handleEdit = async (user_id) => {
      console.log(user_id);
      // if (state.dept_list.length === 0) {
      //   getSeatAddFun();
      // }
      state.modalVisible = true;
      const res = await getSeatEdit({
        access_token: sessionStorage.getItem("token"),
        user_id,
      });
      console.log(res);
      state.user_id = user_id;
      const { info, robot, ext } = res.data.data;
      formState.ext_password = ext.password;
      formState.dept_id = info.dept_id;
      formState.username = info.username;
      formState.password = info.pwd;
      formState.da_status = info.da_status + "";
      formState.robot = robot;
      formState.state = info.state === "1" ? true : false;
    };
    const onSelectChange = (selectedRowKeys) => {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      state.selectedRowKeys = selectedRowKeys;
    };
    const add = () => {
      state.modalVisible = true;
      // if (state.dept_list.length !== 0) return;
      // getSeatAddFun();
    };
    const handleOk = async () => {
      // const res = await formRef.value.validate();
      if (state.user_id) {
        formState.state ? (formState.state = 1) : (formState.state = 2);
        const res = await postSeatEdit({
          access_token: sessionStorage.getItem("token"),
          ...formState,
          user_id: state.user_id,
        });
        console.log(res);
        if (res.data.status === 100000) {
          message.success(res.data.msg);
          state.modalVisible = false;
          // formRef.value.resetFields();
          formRest();
          init();
        } else {
          message.error(res.data.msg);
        }
      } else {
        formState.state ? (formState.state = 1) : (formState.state = 2);
        const res = await postSeatAdd({
          access_token: sessionStorage.getItem("token"),
          ...formState,
        });
        console.log(res);
        if (res.data.status === 100000) {
          message.success(res.data.msg);
          state.modalVisible = false;
          // formRef.value.resetFields();
          formRest();
          init();
        } else {
          message.error(res.data.msg);
        }
      }
    };
    const handleDel = async (user_id) => {
      const res = await postSeatDel({
        access_token: sessionStorage.getItem("token"),
        user_id,
      });
      console.log(res);
      if (res.data.status === 100000) {
        message.success(res.data.msg);
        init();
      } else {
        message.error(res.data.msg);
      }
    };
    const pageChange = (e) => {
      requestParams.page = e.current;
      requestParams.limit = e.pageSize;
      init();
    };
    const formBatch = reactive({
      method: "1",
      generatePwd: "1",
    });
    //批量添加分机
    const addMultiple = () => {
      state.visible = true;
    };
    const handleOkMul = () => {
      state.visible = false;
    };
    return {
      value: ref("1"),
      ...toRefs(state),
      //   handleClick,
      columns,
      // handelQuYangChange,
      handleEdit,
      onSelectChange,
      add,
      formState,
      handleOk,
      requestParams,
      formRest,
      handleDel,
      pageChange,
      addMultiple,
      handleOkMul,
      formBatch,
    };
  },
};
</script>
<style>
.seatmodel .ant-form-item-label {
  min-width: 81px;
}
.form-mulp .ant-form-item-label {
  min-width: 100px;
}
.form-mulp {
  margin-right: 30px;
}
.batch-model .ant-form {
  display: flex;
  flex-wrap: wrap;
}
.batch-model .ant-form-item-label {
  min-width: 81px;
}
.batch-model .ant-form-item:nth-child(2n + 1) {
  margin-right: 30px;
}
</style>
<style scoped>
.SeatManage {
  height: 100%;
  background-color: #fff;
  padding: 15px;
  overflow: auto;
}

/* .ant-btn-primary:nth-child(1) {
  margin-right: 6px;
}
.model3 .ant-form {
  margin: 0 25px;
}
.model3 .ant-form-item {
  margin-bottom: 18px;
}
.model3-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
} */
.row {
  display: flex;
  justify-content: space-between;
}
.last {
  width: 100% !important;
}
.last .ant-form-item {
  width: 100%;
}
</style>